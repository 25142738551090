// import Header from '../components/header';
// import Footer from '../components/footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from 'react-router-dom';
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useParams } from 'react-router-dom';
export default function Teststart() {
  const navigate = useNavigate();

  const [selectelanguage, setSelecteLanguage] = useState([]);
  const [selecteoptional, setSelecteOptional] = useState([]);
  const [selectedValue, setSelectedValue] = useState("0");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [timeduration, setTimeDuration] = useState("");
  const [sectionList, setSectionList] = useState("");
  const [selectedSubcategory, setSelectedSubCategory] = useState("");
  const [rollNo, setRollNo] = useState("");
  const [name, setName] = useState("");
  const { test_id } = useParams();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const authToken = localStorage.getItem("token");

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/user/profile`,
          {
            // const response = await fetch(`http://192.168.0.102:1038/user/profile`, {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include token in headers for authorization
            },
          }
        );
        //   console.log(process.env.REACT_APP_BASE_URL);
        //   console.log(response,"user profile")
        if (!response.ok) {
          navigate("/login");
          //   throw new Error('Failed to fetch user profile');
        }
        const userData = await response.json();

        console.log("userdata-------------", userData);

        const {
          name,
          phone,
          email,
          selected_category,
          selected_sub_category,
          roll_number,
        } = userData.user;
        setName(name);
        setRollNo(roll_number);
        setSelectedSubCategory(userData.user.selected_category.name);
        fetchTestLanguageDetails();
        fetchTestOptionalDetails();
        // setIsVerified(userData.user.is_otp_verify);
        // setIsLoading(false);
      } catch (error) {
        // setError(error.message);
        // setIsLoading(false);
      }
    };

    console.log("selectedSubcategory----", selectedSubcategory);

    const fetchFirstAttemptExam = async () => {
      try {
        const authToken = localStorage.getItem("token");

        fetch(
          `${process.env.REACT_APP_BASE_URL}/user/check_first_attempt_test?test_id=${test_id}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
              // Other headers if needed
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            if (result?.message === "already submitted") {
              navigate("/finalresult/" + test_id);
            }
          })
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserProfile();
    fetchFirstAttemptExam();
  }, [test_id]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleSelectLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const fetchTestLanguageDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/test?test_id=${test_id}`
      );
      console.log("response---", response);

      setSelecteLanguage(response.data.test);
      setTimeDuration(response.data.test.total_duration);
    } catch (error) {
      console.error("Error fetching test language details:", error);
    }
  };
  const fetchTestOptionalDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/get_optional_section?test_id=${test_id}`
      );
      setSelecteOptional(response.data);
    } catch (error) {
      console.error("Error fetching test Optional details:", error);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <section
        className="cc "
        style={{
          background: "url(dfddfvbds.png)",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div className="container border">
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ color: "#2e1b1d" }}>{selectedSubcategory}</h3>
              <hr style={{ border: "1px solid #f27e18" }} />
              <h5 style={{ color: "#2e1b1d" }}>General Instructions</h5>
              <ol>
                <li>
                  The clock will be set at the server. The countdown timer at
                  the top right corner of screen will display the remaining time
                  available for you to complete the examination. When the timer
                  reaches zero, the examination will end by itself. You need not
                  terminate the examination or submit your paper.
                </li>
                <li>
                  The Question Palette displayed on the right side of the screen
                  will show the status of each question using one of the
                  following symbols
                  <ol>
                    <li>
                      <img
                        className="mt-2"
                        src="../a4.png"
                        width="5%"
                        style={{ borderRadius: 1 }}
                      />{" "}
                      - You have not visited the question yet.
                    </li>
                    <li>
                      <img
                        className="mt-2"
                        src="../a2.png"
                        width="5%"
                        style={{ borderRadius: 1 }}
                      />{" "}
                      - You have not answered the question.
                    </li>
                    <li>
                      <img
                        className="mt-2"
                        src="../a3.png"
                        width="5%"
                        style={{ borderRadius: 1 }}
                      />{" "}
                      - You have answered the question.
                    </li>
                    <li>
                      <img
                        className="mt-2"
                        src="../a5.png"
                        width="5%"
                        style={{ borderRadius: 1 }}
                      />{" "}
                      - You have NOT answered the question, but have marked the
                      question for review.
                    </li>
                    <li>
                      <img
                        className="mt-2"
                        src="../a6.png"
                        width="5%"
                        style={{ borderRadius: 1 }}
                      />{" "}
                      - You have answered the question, but marked it for
                      review.
                    </li>
                  </ol>
                  <p>
                    The Mark For Review status for a question simply inclicates
                    that you would like to look at that question again. If a
                    question is answered, but marked for review, then the answer
                    will be considered for evaluation unless the status is
                    modified by the candidate.
                  </p>
                </li>
                <li>
                  <h5>Navigating to a Question:</h5>
                  <h6>To answer a question, do the following:</h6>
                  <ul>
                    <li>
                      Click on the question number in the Question Palette at
                      the right of your screen to go to that numbered question
                      directly. Note using this option does NOT save your
                      current question
                    </li>
                    <li>
                      Click on Save & Next to save your answer for the current
                      question and then go to the next question.
                    </li>
                    <li>
                      Click on Mark for Review & save your answer for the
                      current question and also mark and then go to the next
                      question.
                    </li>
                  </ul>
                  <p>
                    Note that your answer for the current question will not be
                    saved, if you navigate to another question directly by
                    clicking on a question number without saving the answer to
                    the previous question.
                  </p>
                  <p>
                    You can view all the questions by clicking on the Question
                    Paper button This feature is provided, so that if you want
                    you can just the entire question paper at a glance
                  </p>
                </li>

                <li>
                  <h5>Answering A Question</h5>
                  <h6>
                    Procedure for answering a multiple choice (MCQ) type
                    question:
                  </h6>
                  <ul>
                    <li>
                      Choose one answer from 4 options (A,B,C,D) given below the
                      question, click on the bubble placed before the chosen
                      option
                    </li>
                    <li>
                      To deselect your chosen answer, click on the bubble of the
                      chosen option again or click on the <b>Clear Response</b>{" "}
                      button
                    </li>
                    <li>
                      To change your chosen answer, bubble of another option.
                    </li>
                    <li>
                      To save your answer, you MUST click on the{" "}
                      <b>Save & Next</b>
                    </li>
                  </ul>
                </li>
                <li>
                  <h6>
                    Procedure for answering a numerical answer type question
                  </h6>
                  <ul>
                    <li>
                      To enter a number as your answer, virtual numerical keypad
                    </li>
                    <li>
                      A fraction (e.g. -0.3 or-3) can be entered as an answer
                      with or without "0" before the decimal point. As many as
                      four decimal points, e.g. 12.5435 or 0.003 or -932.6711 or
                      12.62 can be entered.
                    </li>
                    <li>
                      To clear your answer, click on the Clear Response button
                    </li>
                    <li>
                      To save your answer, you MUST click on the Save & Next
                    </li>
                  </ul>
                </li>
                <li>
                  To mark a question for review, click on the Mark for Review &
                  Next button. If an answer is selected (for MCQ/MCAQ) entered
                  (for numerical answer type) for a question that is{" "}
                  <b>Marked for Review</b> , that answer will be considered in
                  the evaluation unless the status is modefied by the candidate.
                </li>
                <li>
                  To change your answer to a question that is already been
                  answered, first select that question for answering and then
                  follow the procedure for answering that type of question.
                </li>
                <li>
                  Note that ONLY Questions for which answers are saved or marked
                  for review after answering will be considered for evaluation.
                </li>
                <li>
                  Sections in this question paper are displayed on the top bar
                  of the screen. Questions in a section can be viewed by
                  clicking on the name of that section. The Section you are
                  currently viewing will be highlighted.
                </li>
                <li>
                  After clicking the Save & Next button for the question in a
                  Section, you will automatically be taken to the first question
                  of the next Section in sequence.
                </li>
                <li>
                  You can move the mouse cursor over the name of a Section to
                  view the answering status for that Section.
                </li>
              </ol>
            </div>
            <div className="col-md-12">
              <p className="text-bold">
                Choose your default language :
                <select
                  value={selectedLanguage}
                  onChange={handleSelectLanguage}
                >
                  <option value="">--Select--</option>
                  {selectelanguage.language &&
                    selectelanguage.language.map((lang) => (
                      <option key={lang._id} value={lang._id}>
                        {lang.name}
                      </option>
                    ))}
                </select>
              </p>
              {/* <div>Selectd Language : {selectedLanguage}</div> */}
            </div>
            <div className="col-md-12">
              {selecteoptional &&
                selecteoptional.test_section_details &&
                selecteoptional.test_section_details.length > 0 && (
                  <p className="text-bold">
                    Choose Optional Subject:
                    <select value={selectedValue} onChange={handleSelectChange}>
                      <option value="">--Select--</option>
                      {selecteoptional.test_section_details.map((opts) => (
                        <option key={opts._id} value={opts._id}>
                          {opts.test_section_id.name}
                        </option>
                      ))}
                    </select>
                  </p>
                )}
            </div>
            <div className="col-md-12">
              <p className="text-danger">
                Please note all questions will appear in your default language.
                This language can be changed for a particular question later on{" "}
              </p>
              <hr style={{ border: "1px solid" }} />
            </div>
            <div className="col-md-12">
              <h6>Declaration :</h6>
              <p className="test-instructions-declaration ng-binding">
                <input type="checkbox" name="instructions" /> I have read all
                the instructions carefully and have understood them. I agree not
                to cheat or use unfair means in this examination. I understand
                that using unfair means of any sort for my own or someone else’s
                advantage will lead to my immediate disqualification.
                <span ng-if="!fromOrganisation" class="ng-binding ng-scope">
                  The decision of sarkariwebsresult.com will be final in these
                  matters and cannot be appealed.
                </span>
              </p>
            </div>
            <div className="col-md-12 text-center">
              <a
                href={
                  "/start/" +
                  test_id +
                  "/" +
                  selectedLanguage +
                  "/" +
                  selectedValue +
                  "/" +
                  timeduration
                }
              >
                <button type="button" className="btn btn-lg btn-success">
                  Start
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
}
